import { Suspense, useEffect, useState } from 'react'
import { Canvas as GraphicsCanvas, extend, useThree } from '@react-three/fiber'
import { suspend } from 'suspend-react'
import {
  Html,
  OrbitControls,
  PerspectiveCamera,
  useProgress,
} from '@react-three/drei'
import {
  EffectComposer,
  Bloom,
  DepthOfField,
  Vignette,
  Noise,
} from '@react-three/postprocessing'
import { BlendFunction, Resizer, KernelSize } from 'postprocessing'
import { config, useTransition, a } from 'react-spring'
import { Altar } from './altar'
import { Fireflies } from './fireflies'
import { Starfield } from './starfield'
import swipeIcon from '../img/swipe-icon.svg'
import { Orb } from './orb'
import { Fire } from './fire'
import { constants } from '../constants'
import { Waveform } from './waveform'
import { useSpring, animated } from '@react-spring/three'
import { createAudio } from '../audio'

const scale = Array.from({ length: 100 }, () => 0.5 + Math.random() * 5)

/**
 * Core WebGL scene which should take up the entire viewport.
 * @param {*} audioBuffer
 */
export const Canvas = ({ audioBuffer }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  // suspend-react is the library that r3f uses internally for useLoader. It caches promises and
  // integrates them with React suspense. You can use it as-is with or without r3f.
  const { gain, context, pollPerFrameAudioData, data } = suspend(
    () => createAudio(audioBuffer),
    [audioBuffer]
  )

  useEffect(() => {
    const handleKeyDown = (e) => {
      const key = e.key
      console.log(`Pressed ${key}.`)
    }

    // Connect the gain node, which plays the audio
    gain.connect(context.destination)

    document.addEventListener('keydown', handleKeyDown, true)

    // Disconnect our event listeners on unmount!
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      gain.disconnect()
    }
  }, [gain, context])

  return (
    <div
      className="canvas-wrapper"
      style={{ width: constants.CANVAS_WIDTH, height: constants.CANVAS_HEIGHT }}
    >
      <GraphicsCanvas
        shadows
        dpr={[1, 2]}
        onCreated={(state) =>
          state.gl.setClearColor(constants.CLEAR_COLOR, 0.3)
        }
      >
        <fog
          attach="fog"
          color={constants.FOG_COLOR}
          near={constants.FOG_NEAR}
          far={constants.FOG_FAR}
        />
        <PerspectiveCamera
          makeDefault
          position={constants.CAMERA_POSITION}
          fov={constants.CAMERA_FOV}
        />

        <Starfield radius={200} depth={100} count={500} factor={5} />
        <Altar />
        <Fire scale={[0.5, 2.1, 0.5]} position={[0.15, -1.35, -3.3]} />
        <Orb size={420} position={[0.15, -1.4, -3.5]} color={[0.2, 1.0, 0.2]} />
        <Fireflies
          count={scale.length}
          color={[237, 230, 165]}
          size={scale}
          position={[0, -2.5, 0]}
          scale={[30, 4, 16]}
          speed={scale.length / 100}
          audioData={pollPerFrameAudioData}
        />

        {/* <Waveform /> */}

        <OrbitControls
          autoRotate
          enableZoom={false}
          enablePan={false}
          enableDamping={true}
          minZoom={0}
          autoRotateSpeed={-0.3}
          minPolarAngle={Math.PI / 2}
          maxPolarAngle={-Math.PI / 2}
          target={[0, -1, 0]}
        />

        <EffectComposer
          depthBuffer={true}
          disableNormalPass={true}
          stencilBuffer={true}
        >
          <DepthOfField
            focusDistance={0.01}
            focalLength={0.02}
            bokehScale={2}
          />
          <Noise blendFunction={BlendFunction.COLOR_DODGE} opacity={0.2} />
          <Bloom
            intensity={0.8}
            width={Resizer.AUTO_SIZE}
            height={Resizer.AUTO_SIZE}
            kernelSize={KernelSize.MEDIUM}
            luminanceThreshold={0.1} // Raise this value to mask out darker elements in the scene.
            luminanceSmoothing={0.2} // Smoothness of the luminance threshold. Range is [0, 1]
          />
          <Vignette eskil={false} offset={0.1} darkness={0.8} />
        </EffectComposer>
      </GraphicsCanvas>
    </div>
  )
}

/**
 * Dead simple coaching overlay for telling the user to pan around the scene :).
 * @param {() => {})} onDone Closure for dismissal.
 * @returns
 */
export const CanvasGuide = ({ onDone }) => {
  const [show, set] = useState(false)
  const [frequencyShown, setFrequencyShown] = useState(0)
  const transitions = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 0.5 },
    leave: { opacity: 0 },
    reverse: show,
    delay: 200,
    config: config.molasses,
    onRest: () => {
      set(!show)
      setFrequencyShown(frequencyShown + 1)

      if (frequencyShown >= 6) {
        console.log('Done showing canvas guide.')
        onDone()
      }
    },
  })

  return transitions(
    (styles, item) =>
      item && (
        <a.div style={styles} className="canvas-guide">
          <img src={swipeIcon} width={20} />
          <p>tap and drag to pan around the scene</p>
        </a.div>
      )
  )
}
