/**
 * App-wide global constants.
 */
export const constants = {
  // Debug helpers
  DEBUG_MODE: true,

  // Canvas
  CANVAS_WIDTH: `100vw`,
  CANVAS_HEIGHT: `100vh`,
  CLEAR_COLOR: `#C02163`,

  // Camera
  CAMERA_POSITION: [0, 3, 8],
  CAMERA_FOV: 70,

  // Fog
  FOG_COLOR: `#FAF84C`,
  FOG_NEAR: 18, // ['#E7E545', 18, 120],
  FOG_FAR: 120,

  // Models
  ALTAR_GLB_MODEL_PATH: '/altar-single-geometry.glb',
  ALTAR_BAKED_TEXTURE_PATH: '/altar-baked.jpg',
  ALTAR_SCENE_BASE_COLOR_TEXTURE_PATH: '/Scene_-_Root_baseColor.png',
  FIRE_GLB_MODEL_PATH: '/fire.glb',

  // Audio
  AUDIO_ASSET_PATH: `/its-u_v5.mp3`,
  AUDIO_LOOP: true,
}

export const strings = {
  announcement: `
    We're getting married! Please join us at our wedding reception on
    Saturday, 26th November 2022 at
  `,

  venueLink: `
    https://goo.gl/maps/EE4BhN5jGXh2dFD86
  `,

  venue: `
    The Chateaux at Fox Meadows in Broomfield, Colorado
  `,

  time: `
    at 6.30pm
  `,

  happenings: `
    Dinner, festivities and lots of dancing to follow. Formal attire is
    encouraged!
  `,

  rsvpTitle: `
    The Invite
  `,

  rsvpLink: `
    https://pallaviandprayash.rsvpify.com
  `,

  rsvpCtaInstructions: `
    Click the button below to RSVP. Please let us know by October 25th, 2022
    — the sooner the better! We can't wait to see you there! 🙂
  `,

  rsvpCta: `
    rsvp
  `,

  giftPrompt: `
    Your presence at our wedding is the greatest gift we could ask for.
    If you insist, the best way you can support us is by contributing to our
    honeymoon fund. You can do so by visiting the registry below. Thank you
    for your generosity! 😊
  `,

  registryTitle: `
    Gifts
  `,

  registryCta: `
    registry
  `,

  registryLink: `
    http://zola.com/registry/pallaviandprayash
  `,
}
