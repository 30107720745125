import { useRef, useEffect } from 'react'
import { Color, sRGBEncoding, TextureLoader } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { extend, useLoader } from '@react-three/fiber'
import { shaderMaterial, useGLTF } from '@react-three/drei'
import glsl from 'babel-plugin-glsl/macro'
import { Orb } from './orb'
import { constants } from '../constants'

const DEBUG_MODE = false

// useLoader.preload(constants.ALTAR_BAKED_TEXTURE_PATH)
// useLoader.preload(constants.ALTAR_SCENE_BASE_COLOR_TEXTURE_PATH)

/**
 * Loads the entire scene's 3D model in GLTF and its baked texture.
 */
export const Altar = ({ ...props }) => {
  const portalMaterialRef = useRef()
  const [bakedSceneTex, bakedFireTex] = useLoader(TextureLoader, [
    constants.ALTAR_BAKED_TEXTURE_PATH,
    constants.ALTAR_SCENE_BASE_COLOR_TEXTURE_PATH,
  ])

  bakedSceneTex.encoding = sRGBEncoding
  bakedFireTex.encoding = sRGBEncoding

  const { nodes } = useGLTF(constants.ALTAR_GLB_MODEL_PATH)
  const { nodes: fireNodes } = useGLTF(constants.FIRE_GLB_MODEL_PATH)
  const excludedKeys = ['poleLightA', 'poleLightB', 'portalLight']

  return (
    <group {...props} dispose={null}>
      <group rotation={[0, 0, 0]} position={[0, -3, 0]} scale={2}>
        <mesh geometry={nodes.poleLightA.geometry} material-color="#ECFF93" />
        <mesh geometry={nodes.poleLightB.geometry} material-color="#ECFF93" />
        <mesh geometry={fireNodes.Campfire_0.geometry}>
          <meshBasicMaterial map={bakedFireTex} map-flipY={false} />
        </mesh>
        <mesh geometry={nodes.baked.geometry}>
          <meshBasicMaterial map={bakedSceneTex} map-flipY={false} />
        </mesh>
      </group>
    </group>
  )
}

// Unused for now!
const DebugPortal = () => {
  const { nodes } = useLoader(GLTFLoader, '/altar-single-geometry.glb')
  const { nodes: fireNodes } = useGLTF('/fire.glb')
  // useFrame((state, delta) => (portalMaterial.current.uTime += delta))

  return (
    DEBUG_MODE && (
      <mesh
        geometry={nodes.portalLight.geometry}
        position={nodes.portalLight.position}
        rotation={nodes.portalLight.rotation}
      >
        <portalMaterial
          ref={portalMaterial}
          blending={AdditiveBlending}
          uColorStart="pink"
          uColorEnd="white"
        />
      </mesh>
    )
  )
}

extend({
  // shaderMaterial creates a THREE.ShaderMaterial, and auto-creates uniform setter/getters
  // extend makes it available in JSX, in this case <portalMaterial />
  PortalMaterial: shaderMaterial(
    {
      uTime: 0,
      uColorStart: new Color('hotpink'),
      uColorEnd: new Color('white'),
    },
    glsl`
    varying vec2 vUv;
    void main() {
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectionPosition = projectionMatrix * viewPosition;
      gl_Position = projectionPosition;
      vUv = uv;
    }`,
    glsl`
    #pragma glslify: cnoise3 = require(glsl-noise/classic/3d.glsl) 
    uniform float uTime;
    uniform vec3 uColorStart;
    uniform vec3 uColorEnd;
    varying vec2 vUv;
    void main() {
      vec2 displacedUv = vUv + cnoise3(vec3(vUv * 7.0, uTime * 0.1));
      float strength = cnoise3(vec3(displacedUv * 5.0, uTime * 0.2));
      float outerGlow = distance(vUv, vec2(0.5)) * 4.0 - 1.4;
      strength += outerGlow;
      strength += step(-0.2, strength) * 0.8;
      strength = clamp(strength, 0.0, 1.0);
      vec3 color = mix(uColorStart, uColorEnd, strength);
      gl_FragColor = vec4(color, 1.0);
    }`
  ),
})
