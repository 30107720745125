import { Trail } from './trail'

export const Footer = () => (
  <footer>
    <Trail open={true} delay={750}>
      <br />
      <h4 className="footer-text">
        made with ♡
        <br />
        song & code by{' '}
        <a href="https://prayash.io" target="_blank">
          prayash.io
        </a>
        <br />© p & p
      </h4>
    </Trail>
  </footer>
)
