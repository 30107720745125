import * as React from 'react'
import * as THREE from 'three'
import { useThree, useFrame, extend } from '@react-three/fiber'
import { shaderMaterial } from '@react-three/drei'
import glsl from 'babel-plugin-glsl/macro'

const OrbImplMaterial = shaderMaterial(
  { time: 0, pixelRatio: 1 },
  glsl`
    uniform float pixelRatio;
    uniform float time;
    attribute float size;  
    attribute float opacity;
    attribute vec3 color;
    varying vec3 vColor;
    
    float speed = 1.0;
    vec3 noise = vec3(1.0, 1.0, 0.0);

    void main() {
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      modelPosition.y += sin(time * speed + modelPosition.x * noise.x * 100.0) * 0.2;
      modelPosition.z += cos(time * speed + modelPosition.x * noise.y * 100.0) * 0.2;
      modelPosition.x += cos(time * speed + modelPosition.x * noise.z * 100.0) * 0.001;
      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectionPostion = projectionMatrix * viewPosition;
      gl_Position = projectionPostion;
      gl_PointSize = size * 20.0 * pixelRatio;
      gl_PointSize *= (1.0 / - viewPosition.z);
      vColor = color;
    }
  `,

  glsl`
    varying vec3 vColor;
    varying float vOpacity;
    void main() {
      float distanceToCenter = distance(gl_PointCoord, vec2(0.5));
      float strength = 0.05 / distanceToCenter - 0.1;
      gl_FragColor = vec4(vColor, strength);

      // For some reason, I still get a super faint square, so this is kinda a hack.
      if (strength < 0.008) {
          gl_FragColor = vec4(vColor, 0.0);
      }

      #include <tonemapping_fragment>
      #include <encodings_fragment>
    }
  `
)

/** A small radiating orb.
 * @param size Size of orb
 * @param color Color of orb.
 */
export const Orb = React.forwardRef(
  ({ size, color, children, ...props }, forwardRef) => {
    React.useMemo(() => extend({ OrbImplMaterial }), [])
    const pointsRef = React.useRef(null)
    const dpr = useThree((state) => state.viewport.dpr)
    const positionAttr = Float32Array.from([0, 0, 0])
    const sizeAttr = Float32Array.from({ length: 1 }, () => size)
    const colorAttr = Float32Array.from(color)

    useFrame((state) => {
      if (pointsRef.current && pointsRef.current.material)
        pointsRef.current.material.time = state.clock.elapsedTime
    })

    React.useImperativeHandle(forwardRef, () => pointsRef.current, [])

    return (
      <points key={`orb-size-${size}`} {...props} ref={pointsRef}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            args={[positionAttr, 3]}
          />
          <bufferAttribute attach="attributes-size" args={[sizeAttr, 1]} />
          <bufferAttribute attach="attributes-color" args={[colorAttr, 3]} />
        </bufferGeometry>

        <orbImplMaterial transparent pixelRatio={dpr} depthWrite={false} />
      </points>
    )
  }
)
