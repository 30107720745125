import { Trail } from './trail'
import giftIcon from '../img/gift-icon.svg'
import rsvpIcon from '../img/rsvp-icon.svg'

export const Header = ({ loaded, onShowRSVP, onShowGifts }) => (
  <header>
    <div className="content-left heading">
      <Trail open={loaded}>
        <h1 className="handwritten">
          pallavi
          <span>&</span>
          prayash
        </h1>

        <div className="event-details">
          <p>Nov 26th, 2022 @ 6:30pm</p>
          <p>The Chateaux at Fox Meadows</p>

          <div className="line" />
        </div>

        <div className="button-container">
          <div>
            <button className="circle-button" onClick={onShowRSVP}>
              <img src={rsvpIcon} width={23} />
            </button>
            <p>rsvp</p>
          </div>

          <div>
            <button className="circle-button" onClick={onShowGifts}>
              <img src={giftIcon} width={20} />
            </button>
            <p>gifts</p>
          </div>
        </div>
      </Trail>
    </div>
  </header>
)
