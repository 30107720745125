import { React, useEffect, useState, Fragment, Suspense } from 'react'
import { Canvas, CanvasGuide } from './gl/canvas'
import { Trail } from './components/trail'
import { Header } from './components/header'
import { ButtonLink } from './components/button-link'
import { Footer } from './components/footer'
import { Modal } from './components/modal'
import { LoadingScreen } from './loading-screen'
import { constants, strings } from './constants'
import { preloadModels, preloadAudio } from './preloader'

/**
 * Core app container which hosts the WebGL surface and
 * overlaying HTML elements.
 */
export const App = () => {
  const [isLoaded, setLoaded] = useState(false)
  const [start, setStart] = useState(false)
  const [audioBuffer, setAudioBuffer] = useState(null)

  useEffect(() => {
    preloadModels()

    const fetchAudioData = async () => {
      return await preloadAudio(constants.AUDIO_ASSET_PATH)
    }

    fetchAudioData().then((buffer) => {
      console.log('Audio buffer loaded.')
      setAudioBuffer(buffer)
    })
  }, [])

  return (
    <main>
      <Suspense fallback={null}>
        {start && <Canvas audioBuffer={audioBuffer} />}
        {start && <UIElements loaded={start} />}
      </Suspense>
      <LoadingScreen started={start} onStarted={() => setStart(true)} />
    </main>
  )
}

const UIElements = ({ loaded }) => {
  const [showGuide, setShowGuide] = useState(true)
  const [rsvpVisible, setRsvpVisible] = useState(false)
  const [giftsVisible, setGiftsVisible] = useState(false)

  return (
    <Fragment>
      <Header
        loaded={loaded}
        onShowRSVP={() => setRsvpVisible(true)}
        onShowGifts={() => setGiftsVisible(true)}
      />

      <RsvpModal visible={rsvpVisible} onHide={() => setRsvpVisible(false)} />
      <GiftModal visible={giftsVisible} onHide={() => setGiftsVisible(false)} />

      {showGuide && <CanvasGuide onDone={() => setShowGuide(false)} />}
      <Footer />
    </Fragment>
  )
}

const RsvpModal = ({ visible, onHide }) => {
  return (
    <Modal visible={visible} onHide={onHide} title={strings.rsvpTitle}>
      <p>
        {strings.announcement}{' '}
        <a href={strings.venueLink} target="_blank">
          {strings.venue}
        </a>{' '}
        {strings.time}.
      </p>
      <p>{strings.happenings}</p>
      <p>{strings.rsvpCtaInstructions}</p>
      <br />

      <ButtonLink href={strings.rsvpLink} title={strings.rsvpCta} />
    </Modal>
  )
}

const GiftModal = ({ visible, onHide }) => {
  return (
    <Modal visible={visible} onHide={onHide} title={strings.registryTitle}>
      <p>{strings.giftPrompt}</p>

      <br />
      <p>
        <ButtonLink href={strings.registryLink} title={strings.registryCta} />
      </p>
    </Modal>
  )
}
