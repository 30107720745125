import { useGLTF } from '@react-three/drei'
import { constants } from './constants'

/**
 * Preload all models.
 */
export const preloadModels = () => {
  console.log('🛠️ Preloading assets.')

  // https://discourse.threejs.org/t/how-to-preload-the-texture-and-assets-upfront-before-using-it/46802/2
  useGLTF.preload(constants.ALTAR_GLB_MODEL_PATH)
  console.log('Altar model loaded.')

  useGLTF.preload(constants.FIRE_GLB_MODEL_PATH)
  console.log('Fire model loaded.')
}

/**
 *  Fetch audio data and create a buffer source
 * @param {*} url
 * @returns An audio buffer ready to be consumed.
 */
export const preloadAudio = async (url) => {
  const res = await fetch(url)
  const buffer = await res.arrayBuffer()

  return buffer
}
