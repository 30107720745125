import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { animated, config, useTransition } from 'react-spring'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import closeIcon from '../img/close-icon.svg'
import '@reach/dialog/styles.css'

const AnimatedDialogOverlay = animated(DialogOverlay)
const AnimatedDialogContent = animated(DialogContent)

export const Modal = (props) => {
  let { visible, onHide, focusRef = null, children, title } = props

  const modalTransition = useTransition(!!visible, {
    config: visible ? { ...config.stiff } : { duration: 100 },
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  })

  return modalTransition(
    (styles, visible) =>
      visible && (
        <AnimatedDialogOverlay
          allowPinchZoom={false}
          initialFocusRef={null}
          onDismiss={onHide}
          isOpen={visible}
        >
          <AnimatedDialogContent
            className="filter-blur"
            aria-label="modal-content"
            style={styles}
          >
            <div className="modal-content">
              <div className="modal-content-header">
                <button className="modal-close-btn invisible" onClick={onHide}>
                  <img src={closeIcon} width={28} />
                </button>
                <h3 className="modal-title">{title}</h3>
                <button className="modal-close-btn" onClick={onHide}>
                  <img src={closeIcon} width={28} />
                </button>
              </div>
              {children}
            </div>
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      )
  )
}

const Portal = ({ id, children }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return ReactDOM.createPortal(children, document.querySelector(`#${id}`))
}
