import { useTrail, a } from '@react-spring/web'

export const Trail = ({ open, delay = 300, children }) => {
  const items = children
  const trail = useTrail(items.length, {
    delay: delay,
    config: { mass: 10, tension: 1500, friction: 500 },
    opacity: open ? 1 : 0,
    translateY: 0,
    from: { opacity: 0, translateY: 64 },
  })
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} style={style}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  )
}
