import { React } from 'react'

/**
 * A general app-wide button link!
 * @param href the link to externally lead the user to.
 * @param title title for the button itself.
 */
export const ButtonLink = ({ href, title }) => (
  <a href={href} target="_blank" className="button-link">
    <button className="pushable">
      <span className="front">{title}</span>
    </button>
  </a>
)
