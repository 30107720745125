import * as React from 'react'
import * as THREE from 'three'
import {
  PointsProps,
  useThree,
  useFrame,
  extend,
  Node,
} from '@react-three/fiber'
import { shaderMaterial } from '@react-three/drei'
import { suspend } from 'suspend-react'
import glsl from 'babel-plugin-glsl/macro'

const SparklesImplMaterial = shaderMaterial(
  { time: 0, pixelRatio: 1 },
  glsl`
    uniform float pixelRatio;
    uniform float time;
    uniform float energy;

    attribute float size;  
    attribute float speed;  
    attribute float opacity;
    attribute vec3 noise;

    varying vec3 vColor;
    varying float vOpacity;

    void main() {
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      modelPosition.y += sin(time * speed + modelPosition.x * noise.x * 100.0) * 0.2 * energy;
      modelPosition.z += cos(time * speed + modelPosition.x * noise.y * 100.0) * 0.2;
      modelPosition.x += cos(time * speed + modelPosition.x * noise.z * 100.0) * 0.2;
      
      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectionPostion = projectionMatrix * viewPosition;
      gl_Position = projectionPostion;
      gl_PointSize = size * 25. * pixelRatio;
      gl_PointSize *= (1.0 / - viewPosition.z);
      vColor = vec3(0.2, 1.0, 0.2);
      vOpacity = opacity;
    }
  `,

  glsl`
    varying vec3 vColor;
    varying float vOpacity;
    
    void main() {
      float distanceToCenter = distance(gl_PointCoord, vec2(0.5));
      float strength = 0.05 / distanceToCenter - 0.1;
      gl_FragColor = vec4(vColor, strength * vOpacity);
      
      // For some reason, I still get a super faint square, so this is kinda a hack.
      if (strength < 0.008) {
          gl_FragColor = vec4(vColor, 0.0);
      }

      #include <tonemapping_fragment>
      #include <encodings_fragment>
    }
  `
)

/** Point-cloud of fireflies.
 * @param noise Movement factor (default: 1)
 * @param count Number of particles (default: 100)
 * @param speed Speed of particles (default: 1)
 * @param scale The space the particles occupy (default: 1)
 * @param size Size of particles (default: randomized between 0 and 1)
 * @param color Color of particles (default: 100)
 */
export const Fireflies = React.forwardRef(
  (
    {
      noise = 1,
      count = 100,
      speed = 1,
      opacity = 1,
      scale = 1,
      size,
      color,
      url,
      children,
      audioData,
      ...props
    },
    forwardRef
  ) => {
    React.useMemo(() => extend({ SparklesImplMaterial }), [])
    const ref = React.useRef()

    useFrame((state, delta) => {
      const { avg, beats, currentPlaybackTime } = audioData()
    })

    const pointsRef = React.useRef(null)
    const dpr = useThree((state) => state.viewport.dpr)
    const positions = React.useMemo(
      () =>
        Float32Array.from(
          Array.from({ length: count }, () =>
            normalizeVector(scale).map(THREE.MathUtils.randFloatSpread)
          ).flat()
        ),
      [count, scale]
    )

    const sizes = usePropAsIsOrAsAttribute(count, size, Math.random)
    const opacities = usePropAsIsOrAsAttribute(count, opacity)
    const speeds = usePropAsIsOrAsAttribute(count, speed)
    const noises = usePropAsIsOrAsAttribute(count * 3, noise)
    const colors = usePropAsIsOrAsAttribute(
      count,
      isFloat32Array(color) ? Float32Array.from([0, 0, 1.0]) : color
    )

    useFrame((state) => {
      if (pointsRef.current && pointsRef.current.material) {
        pointsRef.current.material.time = state.clock.elapsedTime

        // pointsRef.current.material.time = state.clock.elapsedTime + avg * 0.005
        // pointsRef.current.material.materialColor = new THREE.Vector3(
        //   avg * 20 + 50,
        //   avg * 20 + 50,
        //   avg * 20 + 50
        // ) // vec3(0.2, 1.0, 0.2);

        // // Distribute the instanced planes according to the FFT data
        // for (let i = 0; i < data.length; i++) {
        //   obj.position.set(
        //     i * width * space - (data.length * width * space) / 2,
        //     data[i] / y,
        //     0
        //   )
        //   obj.updateMatrix()
        //   ref.current.setMatrixAt(i, obj.matrix)
        // }
        // // Set the hue according to the frequency average
        // ref.current.material.color.setHSL(avg / 500, 0.75, 0.75)
        // ref.current.instanceMatrix.needsUpdate = true
      }
    })

    // React.useImperativeHandle(forwardRef, () => pointsRef.current, [])

    return (
      <points
        key={`particle-${count}-${JSON.stringify(scale)}`}
        {...props}
        ref={pointsRef}
      >
        <bufferGeometry>
          <bufferAttribute attach="attributes-position" args={[positions, 3]} />
          <bufferAttribute attach="attributes-size" args={[sizes, 1]} />
          <bufferAttribute attach="attributes-opacity" args={[opacities, 1]} />
          <bufferAttribute attach="attributes-speed" args={[speeds, 1]} />
          {/* <bufferAttribute attach="attributes-color" args={[colors, 3]} /> */}
          <bufferAttribute
            array={colors}
            attach="color"
            count={colors.length}
            itemSize={3}
          />
          <bufferAttribute attach="attributes-noise" args={[noises, 3]} />
        </bufferGeometry>

        <sparklesImplMaterial transparent pixelRatio={dpr} depthWrite={false} />
      </points>
    )
  }
)

function isFloat32Array(def) {
  return def && def.constructor === Float32Array
}

function expandColor(v) {
  return [v.r, v.g, v.b]
}

function isVector(v) {
  return (
    v instanceof THREE.Vector2 ||
    v instanceof THREE.Vector3 ||
    v instanceof THREE.Vector4
  )
}

function normalizeVector(v) {
  if (Array.isArray(v)) return v
  else if (isVector(v)) return v.toArray()
  return [v, v, v]
}

function usePropAsIsOrAsAttribute(count, prop, setDefault) {
  return React.useMemo(
    function () {
      if (prop !== undefined) {
        if (isFloat32Array(prop)) {
          return prop
        } else {
          if (prop instanceof THREE.Color) {
            var a = Array.from({ length: count * 3 }, function () {
              return expandColor(prop)
            }).flat()
            return Float32Array.from(a)
          } else if (isVector(prop) || Array.isArray(prop)) {
            var a = Array.from({ length: count * 3 }, function () {
              return normalizeVector(prop)
            }).flat()
            return Float32Array.from(a)
          }
          return Float32Array.from({ length: count }, function () {
            return prop
          })
        }
      }
      return Float32Array.from({ length: count }, setDefault)
    },
    [prop]
  )
}
