import { constants } from './constants'

/**
 * The output of an FFT will give you an array of relative strength in frequency RANGES, not precise frequencies.
 * These ranges are spread out in the spectrum [0,Nyquist frequency]. The Nyquist frequency is one-half of the
 * sample rate. So if your AudioContext.sampleRate is 48000 (Hertz), your frequency bins will range
 * across [0, 24000] (also in Hz).
 *
 * If you are using the default value of 2048 for fftSize in your AnalyserNode, then frequencyBinCount will be
 * 1024 (it's always half the FFT size). This means each frequency bin will represent (24000/1024 = 23.4)
 * approximately 23.4Hz of range - so the bins will look something like this (off-the-cuff, rounding errors may
 * occur here):
 *
 * <code>
 * fData[0] is the strength of frequencies from 0 to 23.4Hz.
 * fData[1] is the strength of frequencies from 23.4Hz to 46.8Hz.
 * fData[2] is the strength of frequencies from 46.8Hz to 70.2Hz.
 * fData[3] is the strength of frequencies from 70.2Hz to 93.6Hz.
 * ...
 * fData[511] is the strength of frequencies from 11976.6Hz to 12000Hz.
 * fData[512] is the strength of frequencies from 12000Hz to 12023.4Hz.
 * ...
 * fData[1023] is the strength of frequencies from 23976.6Hz to 24000Hz.
 * </code>
 *
 * https://webaudioapi.com/book/Web_Audio_API_Boris_Smus_html/ch02.html
 *
 * @param {Promise} buffer A preloaded audio buffer.
 * @returns
 */
export const createAudio = async (buffer) => {
  const context = new (window.AudioContext || window.webkitAudioContext)()
  const analyser = context.createAnalyser()
  const source = context.createBufferSource()
  const sampleRate = context.sampleRate
  // const beatDetector = BeatDetector()

  source.loop = constants.AUDIO_LOOP
  source.buffer = await new Promise((res) =>
    context.decodeAudioData(buffer, res)
  )

  // beatDetector.load(source.buffer)

  // This is why it doesn't run in Safari 🍏🐛. Start has to be called in an onClick event
  // which makes it too awkward for a little demo since you need to load the async data first
  const offset = 0
  source.start(0, offset)

  source.currentTime = offset
  const initTime = context.currentTime

  // Create gain node and an analyser
  const gainNode = context.createGain()
  analyser.fftSize = 256
  console.log(`AnalyserNode fftSize: ${analyser.fftSize}`)

  source.connect(analyser)
  analyser.connect(gainNode)

  // The data array receive the audio frequencies. Remember, it's always half of `fftSize` due to Nyquist frequency!
  const bufferLength = analyser.frequencyBinCount
  console.log(`Audio buffer length: ${bufferLength}`)
  const frequencyArray = new Uint8Array(bufferLength)

  return {
    context,
    source,
    gain: gainNode,
    data: frequencyArray,
    // This function gets called every frame per audio source
    pollPerFrameAudioData: () => {
      // The frequency data is composed of integers on a scale from 0 to 255.
      // Each item in the array represents the decibel value for a specific frequency.
      // The frequencies are spread linearly from 0 to 1/2 of the sample rate.
      // For example, for 48000 sample rate, the last item of the array will represent
      // the decibel value for 24000 Hz.
      analyser.getByteFrequencyData(frequencyArray)

      // console.log(frequencyArray)

      // Calculate a frequency average
      const avg = frequencyArray.reduce(
        (prev, cur) => prev + cur / frequencyArray.length,
        0
      )

      return {
        avg,
      }
    },
  }
}
